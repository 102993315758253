<template>
  <FullScreenDialog
    v-model="hairTypeDialog"
    title="Modifica Tipo Di Capelli"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in hairTypesTab"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in hairTypesTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component :is="tabItem.component"></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="$router.push({path: pathToGoBack})"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

<!-- KEEP COMMENT - ONEWARE-562     
  <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template> -->
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import GeneralForm from "@/components/areas/registries/hairTypes/form/GeneralFormTab.vue"
import hairTypesService from '@/services/hairTypes/hairTypes.service.js'
import hairTypeForm from '@/services/hairTypes/hairType.form.js'

export default {
  name: "HairTypesRegistryEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    GeneralForm
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingHairType: false,
      hairTypeDialog: this.openDialog,
      hairTypesTab: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      hairType: undefined,
      originalUrl: window.location.pathname
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    this.id = this.$route.params.id
    if(this.id) {
      this.fetchHairType()
    }


    let self = this
    hairTypeForm.on('update', function(data) {
      self.hairType = data.hairType
    })

    self.valid = hairTypeForm.valid
    hairTypeForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/registries",
    },
    tabName: {
      type: String,
      default: 'registry'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.hairTypesTab.length; i++) {
        if(this.hairTypesTab[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      hairTypesService.update(this.hairType).then((result) => {
        this.$router.push({path: this.pathToGoBack})
      })
    },
    fetchHairType() {
      this.loadingHairType = true
      hairTypeForm.resetHairType()
      hairTypesService.get(this.id).then((hairType) => {
        this.loadingHairType = false
        hairTypeForm.updateObject(hairType)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.hairTypeDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.hairTypesTab[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
};
</script>